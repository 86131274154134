<template>
    <el-container>
        <el-aside style="width: auto">
            <el-col :span="24">
                <div class="logo"><img :src="isCollapse ? 'jia.png' : 'logo.png'" alt=""></div>
                <el-menu
                    background-color="#313a46"
                    text-color="#8391a2"
                    :default-active="thisRouter.meta.active ? this.thisRouter.meta.active : thisRouter.path"
                    class="el-menu-vertical-demo"
                    :router="true"
                    :collapse="isCollapse">
                    <template v-for="(item) in menu">
                        <el-submenu :index="item.path"
                                    v-if="item.children!==undefined && !item.meta.hidden && (item.meta.permission.indexOf(authInfo.permission)>-1)">
                            <template #title>
                                <i :class="item.meta.icon"></i>
                                <span>{{ item.meta.title }}</span>
                            </template>
                            <template v-for="(child,cinx) in item.children">
                                <el-menu-item
                                    v-if="(!child.meta.hidden  && (child.meta.permission.indexOf(authInfo.permission)>-1)) && (child.path!=='stop' || authInfo.id===87)"
                                    :key="cinx" :index="item.path + child.path">
                                    <template #title>
                                        <span style="padding-left: 10px;">{{ child.meta.title }}</span>
                                    </template>
                                </el-menu-item>
                            </template>
                        </el-submenu>
                        <el-menu-item
                            v-if="item.children===undefined && !item.meta.hidden && (item.meta.permission.indexOf(authInfo.permission)>-1)"
                            :index="item.path">
                            <i :class="item.meta.icon"></i>
                            <template #title>{{ item.meta.title }}</template>
                        </el-menu-item>
                    </template>
                </el-menu>
            </el-col>
        </el-aside>
        <el-container>
            <el-header>
                <div class="topheader">
                    <div class="asideBtn" @click="isCollapse=!isCollapse">
                        <i class="el-icon-s-fold" style="font-size: 28px;"></i>
                    </div>
                    <div>
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item v-for="(item,index) in thisRouter.matched" :key="index"
                                                :to="{ path: item.path }">
                                {{ item.meta.title }}
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="right">
                        <el-dropdown style="margin-right: 40px;">
                            <span class="el-dropdown-link">
                                <i class="el-icon-message-solid" style="font-size: 24px;color: #98a6ad;"></i>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item>黄金糕</el-dropdown-item>
                                    <el-dropdown-item>狮子头</el-dropdown-item>
                                    <el-dropdown-item>螺蛳粉</el-dropdown-item>
                                    <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                                    <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        <el-dropdown @command="right_command">
                            <div class="dropProfile">
                                <el-avatar src="admin.png" size="small"></el-avatar>
                                <div class="info">
                                    <h5>{{ authInfo.nickname }}</h5>
                                    <p>{{ authInfo.permission_name }}</p>
                                </div>
                            </div>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item icon="el-icon-edit" command="clearTemp">清空缓存</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-switch-button" command="logout" divided>退出登录
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>
            </el-header>
            <el-main id="el-main" style=" height: calc(100vh - 60px); overflow-y: auto;">
                <keep-alive>
                    <router-view v-if="thisRouter.meta.keepalive"></router-view>
                </keep-alive>
                <router-view v-if="thisRouter.meta.keepalive==undefined || !thisRouter.meta.keepalive"></router-view>
            </el-main>
        </el-container>
    </el-container>

</template>

<script>
import {useRoute, useRouter, onBeforeRouteUpdate} from "vue-router";
import {routes} from "@/router";
import {auth_info} from "@/request/api";

export default {
    name: "BaseLayout",
    data() {
        return {
            authInfo: {
                username: "",
                nickname: "",
                permission: "",
                permission_name:""
            },
            isCollapse: false,
            thisRouter: {
                path: "",
                meta: {title: ""},
                matched: []
            },
            searchKey: "",
            menu: []
        }
    },
    watch: {
        $route(to, from) {
            document.title = to.meta.title;
            this.thisRouter = to;
        }
    },
    mounted() {
        this.menu = routes;
        console.log("menu", this.menu);

        if (!localStorage.getItem("access_token")) {
            this.$router.replace({path: "/login"});
        } else {
            let access_token = JSON.parse(localStorage.getItem("access_token"));
            let timestamp = Date.parse(new Date()) / 1000;
            if (access_token.end_time < timestamp) {
                //token已过期，若未更新则说明无法更新，需重新登录
                this.$router.replace({path: "/login"});
            }
        }
        auth_info().then(res => {
            this.authInfo = res.data;
        })
        this.thisRouter = this.$route;
        console.log("thisrouter",this.thisRouter)
    },
    methods: {
        right_command(command){
            if(command==="clearTemp"){
                this.clearTemp();
            }else if(command==="logout"){
                this.logout();
            }
        },
        clearTemp(){
            this.$confirm('您确定要清除缓存吗？', '提醒', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                localStorage.clear();
                sessionStorage.clear();
                this.$message.success("清除成功");
                setTimeout(function (){
                    window.location.href = location.href;
                },1500)
            }).catch(() => {

            })
        },
        logout() {
            this.$confirm('您确定要退出系统吗？', '退出提醒', {
                confirmButtonText: '退出',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // localStorage.clear();
                this.$router.replace({path: "/login"});
            }).catch(() => {

            })
        }
    }
}
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
</style>
<style lang="scss">
.el-menu {
    border-right: none !important;
}

.pagetop {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .page-title {
        font-size: 18px;
    }
}

.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
    color: #727cf5 !important;
}

.el-menu-vertical-demo {

}

.el-form-item__content {
    display: flex;
}

.el-header {
    display: flex;
    box-shadow: 0 0 35px 0 rgb(154, 161, 171, 0.15);
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 500;

    .topheader {
        width: 100%;
        height: 60px;
        display: flex;
        align-self: center;
        align-items: center;

        .asideBtn {
            margin-right: 20px;
        }

        .right {
            margin-left: auto;
            display: flex;
            align-items: center;
            align-self: center;

            .dropProfile {
                height: 60px;
                padding: 0px 15px;
                display: flex;
                align-self: center;
                align-items: center;
                background-color: #fafbfd;
                border: 1px solid #f1f3fa;
                color: #98a6ad;

                .info {
                    margin-left: 10px;

                    h5 {
                        margin: 0 0 5px 0;
                        font-size: 0.9rem;
                    }

                    p {
                        margin: 0px;
                        font-size: 12px;
                    }
                }

            }
        }
    }
}

.el-aside {
    display: flex;
    background-color: #313a46;
    position: sticky;
    top: 0;

    .logo {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        padding: 25px 0 25px 0;
        position: sticky;
        top: 0;

        img {
            height: 30px;
        }
    }
}

.el-menu-item.is-active {
    color: #727cf5;
}
</style>

const state = {
    page: 1
}

const mutations = {
    SET_QUERY_PARAMS: (state, page) => {
        state.page = page
    }
}

const actions = {
    setQueryParams({commit}, page) {
        commit('SET_QUERY_PARAMS', page)
    }
}

const dna_components = [];

const crowd = [
    {
        name: "孕妇",
        children: [
            {name: "0~40周"},
            {name: "0~12周"},
            {name: "21~40周"},
            {name: "其他"}
        ]
    },
    {
        name: "哺乳期妇女",
        children: []
    },
    {
        name: "儿童",
        children: [
            {name: "早产儿"},
            {name: "0~28天新生儿"},
            {name: "1~12个月婴儿"},
            {name: "1~3岁幼儿"},
            {name: "0~14岁儿童"},
            {name: "15~18岁青少年"},
            {name: "胎儿"},
            {name: "其他"}
        ]
    },
    {
        name: "老人",
        children: [
            {name: "60岁以上老人"},
            {name: "65岁以上老人"},
            {name: "70岁以上老人"},
            {name: "75岁以上老人"},
            {name: "80岁以上老人"},
            {name: "其他"}
        ]
    },
    {
        name: "肝功能不全",
        children: [
            {name: "轻度肝功能不全/A级（肝功五项评分5~6分）"},
            {name: "中度肝功能不全/B级(肝功五项评分7~9分)"},
            {name: "重度肝功能不全/C级（肝功五项评分大于10分）"}
        ]
    },
    {
        name: "肾功能不全",
        children: [
            {name: "正常：GFR>=90ml/min"},
            {name: "轻度肾功能不全/代偿期：GFR为60-89ml/min"},
            {name: "中度肾功能不全/失代偿期：GFR为30-59ml/min"},
            {name: "中度肾功能不全/失代偿期：GFR为30-59ml/min"},
            {name: "中度肾功能不全/失代偿期：GFR为30-59ml/min"},
        ]
    },
    {
        name: "备孕期妇女",
        children: []
    },
    {
        name: "无避孕措施妇女",
        children: []
    },
    {
        name: "备孕男性",
        children: []
    }
]

const levels = [
    {
        label: "首选考虑",
        children: [
            {label: "首选考虑方案"},
            {label: "首选考虑的替代方案"},
            {label: "一线用药方案"},
            {label: "一线用药的替代方案"}
        ]
    },
    {
        label: "次选考虑",
        children: [
            {label: "次选考虑方案"},
            {label: "次选考虑的替代方案"},
            {label: "二线用药方案"},
            {label: "二线用药的替代方案"}
        ]
    },
    {
        label: '',
        children: [
            {
                label: "可考虑方案"
            },
            {
                label: "不确定是否有用的方案"
            },
            {
                label: "谨慎考虑方案"
            },
            {
                label: "考虑调整的方案"
            },
            {
                label: "不再选择的方案"
            },
            {
                label: "不考虑方案"
            },
            {
                label: "错误方案"
            }
        ]
    },

];

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    dna_components,
    crowd,
    levels
}

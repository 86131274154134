import axios from "axios";
import qs from "qs";
import {update_token} from "@/request/api";
import {router} from "@/router";

axios.defaults.baseURL = '/risk/'

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
axios.defaults.timeout = 3000000;

axios.interceptors.request.use(
    config => {
        if(config.url.indexOf("common")>-1 || config.url.indexOf("login")>-1 || config.url.indexOf("post_log")>-1){
            //公共控制器，直接返回
            return config;
        }else{
            let timestamp = Date.parse(new Date())/1000;
            let access_token = localStorage.access_token;
            if(!access_token){
                console.log("token不存在",access_token);
                this.$router.replace({path:"/login"});
            }else{
                //本地token存在
                access_token = JSON.parse(access_token);
                if(timestamp > (access_token['end_time']-1100) && config.url.indexOf("update_token")<0 && config.url.indexOf("refresh")<0){
                    console.log("需要更新token了");
                    update_token({}).then(res=>{
                        if(res.code===1){
                            access_token = res.data;
                            localStorage.setItem("access_token",JSON.stringify(res.data));//保存至本地
                        }else{
                            return false;
                        }
                    })
                }
                if(access_token && access_token['end_time']>timestamp){
                    config.headers['Authorization'] = 'Bearer ' + access_token.access_token;
                    config.headers['timestamp'] = timestamp
                }
            }
            return config;
        }
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if(response.status==200){
            return Promise.resolve(response);
        }else{
            return Promise.reject(response)
        }
    },
    error => {
        if(error.response.status==401){
            router.replace({path:"/login"});
            return Promise.reject(error.response.status)
        }
        return Promise.reject(error.response.status)
    }
);

export default {
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: qs.stringify(data),
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                params: data,
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    download(url, data,filename) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                params: data,
                responseType: 'blob',
            })
                .then(res => {
                    if (typeof window.chrome !== 'undefined') {
                        // Chrome version
                        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        const urls = window.URL.createObjectURL(blob)
                        let a = document.createElement('a') // 生成虚拟a标签
                        a.href = urls
                        a.download = filename + '.xlsx'
                        a.click()
                        //document.body.removeChild(a) // 下载完成移除元素
                        window.URL.revokeObjectURL(urls) // 释放掉blob对象
                    } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        // IE version
                        const blob = new Blob([res.data], { type: 'application/force-download' })
                        window.navigator.msSaveBlob(blob, filename)
                    } else {
                        // Firefox version
                        const file = new File([res.data], filename, { type: 'application/force-download' })
                        window.open(URL.createObjectURL(file))
                    }
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    postdown(url, data,filename) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: qs.stringify(data),
                responseType: 'blob',
                timeout:3000000
            })
                .then(res => {
                    if (typeof window.chrome !== 'undefined') {
                        // Chrome version
                        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        const urls = window.URL.createObjectURL(blob)
                        let a = document.createElement('a') // 生成虚拟a标签
                        a.href = urls
                        a.download = filename
                        a.click()
                        //document.body.removeChild(a) // 下载完成移除元素
                        window.URL.revokeObjectURL(urls) // 释放掉blob对象
                    } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        // IE version
                        const blob = new Blob([res.data], { type: 'application/force-download' })
                        window.navigator.msSaveBlob(blob, filename)
                    } else {
                        // Firefox version
                        const file = new File([res.data], filename, { type: 'application/force-download' })
                        window.open(URL.createObjectURL(file))
                    }
                    resolve(true)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
};

<template>
    <div id="app">
        <router-view @error="event => handleError(event)"/>
    </div>
</template>
<script>
import Vue from "vue";
import store from "@/common/store";

export default {
    mounted() {
        window.onerror = function(message,source,line,column,error) {
            console.log('window.onerror----', message,source,line,column,error);
        }
        this.$http.post("get_dna_components").then(res=>{
            this.$store.dna_components = res.data;
        })
    },
    methods:{
        handleError(event) {
            console.log('handleError-----', event);
            throw new Error('图片加载失败了'); // 手动抛出异常，以便全局事件可以监听到
        }
    }
}
</script>

<style lang="scss">
#app {
    color: #606266; // 常规文字
    min-height: 100vh;
    display: flex;
}

body {
    margin: 0;
    padding: 0;
}

a {
    color: #727cf5;
}

ul {
    padding: 0;
}
textarea {
    font-family: inherit !important;
}
#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.tableTool {
    display: flex;
    align-items: center;
    align-self: center;
    .el-form-item{
        margin-bottom: 0;
    }
    .search {
        display: flex;
        align-self: center;
        align-items: center;

        .el-input, .el-select {
            margin-right: 15px;
        }
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 8px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #727CF5;
}

.el-dialog__body {
    padding: 20px !important;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.el-dialog__header {
    padding: 20px !important;
}

.el-table__expanded-cell[class*=cell] {
    padding: 10px;
}

.el-upload-list--picture-card .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
}

.el-upload--picture-card {
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
}
.el-cascader-menu__wrap{
    height: 530px;
}

.diagnosis_colors0 {
    background-color: hsl(0, 60%, 70%,0.5);
    color: #333;
    border: none;
}

.diagnosis_colors1 {
    background-color: hsl(45, 60%, 70%,0.5);
    color: #000;
    border: none;
}

.diagnosis_colors2 {
    background-color: hsl(90, 60%, 70%,0.5);
    color: #333;
    border: none;
}

.diagnosis_colors3 {
    background-color: hsl(135, 60%, 70%,0.5);
    color: #333;
    border: none;
}

.diagnosis_colors4 {
    background-color: hsl(180, 60%, 70%,0.5);
    color: #333;
    border: none;
}

.diagnosis_colors5 {
    background-color: hsl(225, 60%, 70%,0.5);
    color: #fff;
    border: none;
}

.diagnosis_colors6 {
    background-color: hsl(270, 60%, 70%);
    color: #fff;
    border: none;
}
.diagnosis_colors7 {
    background-color: hsl(315, 60%, 70%);
    color: #fff;
    border: none;
}
.diagnosis_colors8 {
    background-color: hsl(360, 60%, 70%);
    color: #fff;
    border: none;
}
</style>

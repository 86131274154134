import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import {router} from './router'
import request from "./request"
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$http = request;

import store from "@/common/store";
Vue.prototype.$store = store;



new Vue({
    router,
    render: h => h(App)
}).$mount('#app')



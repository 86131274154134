import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from "@/layouts/BaseLayout";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/home",
        component: BaseLayout,
        meta: {
            hidden: false,
            keepAlive: true,
            icon: "el-icon-s-platform",
            title: "控制台",
            permission: ["", "admin", "examine", "pharmacist", "auditor1", "auditor2", "enzyme", "auditor3"]
        },
        children: [
            {
                path: "home",
                component: () => import("../views/index/index"),
                meta: {
                    title: '数据统计',
                    hidden: false,
                    permission: ["", "admin", "examine", "pharmacist", "auditor1", "auditor2", "enzyme", "auditor3"]
                },
            },
            {
                path: "task_data",
                component: () => import("../views/index/task_data"),
                meta: {title: '任务统计', hidden: false, permission: ["", "admin"]},
            },
            {
                path: "check_local",
                component: () => import("../views/index/check_local"),
                meta: {
                    title: '本地数据对比',
                    hidden: false,
                    permission: ["", "admin", "examine", "pharmacist", "auditor1", "auditor2", "enzyme", "auditor3"]
                },
            }
        ]
    },
    {
        path: '/risk/',
        redirect: "/risk/index",
        component: BaseLayout,
        meta: {
            hidden: false,
            keepAlive: true,
            icon: "el-icon-s-platform",
            title: "风险管理",
            permission: ["", "admin", "examine","enzyme"]
        },
        children: [
            {
                path: "index",
                component: () => import("../views/risk_base/index"),
                meta: {title: '全部风险', hidden: false, permission: ["admin", "examine", "pharmacist", "enzyme"]},
            },
            {
                path: "wait_import",
                component: () => import("../views/risk_base/wait_import"),
                meta: {title: '待导入列表', hidden: false, permission: ["admin"]},
            },
            {
                path: "label_list",
                component: () => import("../views/risk_base/label_list"),
                meta: {title: '标签一览', hidden: false, permission: ["admin"]},
            },
            {
                path: "status3",
                component: () => import("../views/risk_base/status3"),
                meta: {title: '待分配', hidden: false, permission: ["admin", "examine", "pharmacist"]},
            },
            {
                path: "status0",
                component: () => import("../views/risk_base/status0"),
                meta: {title: '未完善', hidden: false, permission: ["admin"]},
            },
            {
                path: "status1",
                component: () => import("../views/risk_base/status1"),
                meta: {title: '待审核', hidden: false, permission: ["admin", "examine", "pharmacist"]},
            },
            {
                path: "status2",
                component: () => import("../views/risk_base/status2"),
                meta: {title: '已完成', hidden: false, permission: ["admin", "examine", "pharmacist"]},
            },
            {
                path: "edit",
                name: "risk_base_edit",
                component: () => import("../views/risk_base/edit"),
                meta: {title: '新增药品风险数据', hidden: true, permission: ["", "admin", "examine", "pharmacist", "enzyme"]},
            },
            {
                path: "wait_detail",
                name: "risk_base_wait_detail",
                component: () => import("../views/risk_base/wait_detail"),
                meta: {title: '详情', hidden: true, permission: ["", "admin", "examine", "pharmacist", "enzyme"]},
            },
        ]
    },
    {
        path: '/task/',
        redirect: "/task/index",
        component: BaseLayout,
        meta: {
            hidden: false,
            keepAlive: true,
            icon: "el-icon-s-platform",
            title: "任务管理",
            permission: ["pharmacist", "enzyme"]
        },
        children: [
            {
                path: "stop",
                component: () => import("../views/stop/index"),
                meta: {title: '停药管理', hidden: false, permission: ["pharmacist"]},
            },
            {
                path: "index",
                component: () => import("../views/task/index"),
                meta: {title: '全部任务', hidden: false, permission: ["admin", "pharmacist", "enzyme"]},
            },
            {
                path: "edit",
                name: "task_edit",
                component: () => import("../views/task/edit"),
                meta: {title: '任务详情', hidden: true, permission: ["pharmacist", "enzyme"]},
            },
        ]
    },
    {
        path: '/disease/',
        redirect: "/disease/index",
        component: BaseLayout,
        meta: {
            hidden: false,
            keepAlive: true,
            icon: "el-icon-s-platform",
            title: "疾病模块",
            permission: ["admin"]
        },
        children: [
            {
                path: "index",
                component: () => import("../views/disease/index"),
                meta: {title: '疾病管理', hidden: false, permission: ["admin", "pharmacist", "enzyme"]},
            },
            //疾病详情
            {
                path: "detail",
                name: "disease_detail",
                component: () => import("../views/disease/detail"),
                meta: {title: '疾病详情', hidden: true, permission: ["admin", "pharmacist", "enzyme"]},
            },
            {
                path: "indication",
                component: () => import("../views/disease/indication"),
                meta: {title: '适应症关联', hidden: false, permission: ["admin", "pharmacist", "enzyme"]},
            },
            {
                path: "indication_examine",
                component: () => import("../views/disease/examine"),
                meta: {title: '审核适应症', hidden: false, permission: ["admin", "pharmacist", "enzyme"]},
            },
            {
                path: "examine_detail",
                component: () => import("../views/disease/examine_detail"),
                meta: {title: '审核适应症详情', hidden: true, permission: ["admin", "pharmacist", "enzyme"]},
            },
        ]
    },
    {
        path: '/pharmacist/',
        redirect: "/pharmacist/index",
        component: BaseLayout,
        meta: {
            hidden: false,
            keepAlive: true,
            icon: "el-icon-s-platform",
            title: "药师管理",
            permission: ["admin"]
        },
        children: [
            {
                path: "index",
                component: () => import("../views/pharmacist/index"),
                meta: {title: '全部药师', hidden: false, permission: ["admin"]},
            },
            {
                path: "auditor",
                component: () => import("../views/pharmacist/auditor"),
                meta: {title: '审核账号', hidden: false, permission: ["admin"]},
            }
        ]
    },
    {
        path: '/examine/',
        redirect: "/examine/index",
        component: BaseLayout,
        meta: {
            hidden: false,
            keepAlive: true,
            icon: "el-icon-s-platform",
            title: "审核药师任务",
            permission: ["admin", "auditor1", "auditor2", "auditor3"]
        },
        children: [
            {
                path: "index",
                component: () => import("../views/examine/index"),
                meta: {title: '全部任务', hidden: false, permission: ["admin", "auditor1", "auditor2", "auditor3"]},
            },
            {
                path: "detail",
                name: "examine_detail",
                component: () => import("../views/examine/detail"),
                meta: {title: '任务详情', hidden: true, permission: ["admin", "auditor1", "auditor2", "auditor3"]},
            },
            {
                path: "interaction",
                name: "interaction",
                component: () => import("../views/examine/interaction"),
                meta: {title: '相互作用检查', hidden: false, permission: ["admin"]},
            },
            {
                path: "ex_stop",
                component: () => import("../views/stop/examine"),
                meta: {title: '停药审核', hidden: false, permission: ["admin", "auditor1", "auditor2", "auditor3"]},
            },
            {
                path: "ex_check",
                component: () => import("../views/stop/check"),
                meta: {title: '停药检查', hidden: false, permission: ["admin", "auditor1", "auditor2", "auditor3"]},
            },
        ]
    },
    {
        path: '/setting/',
        redirect: "/setting/parameters",
        component: BaseLayout,
        meta: {
            hidden: false,
            keepAlive: true,
            icon: "el-icon-s-platform",
            title: "系统设置",
            permission: ["", "admin", "examine", "service","enzyme"]
        },
        children: [
            {
                path: "parameters",
                component: () => import("../views/setting_parameters/index"),
                meta: {title: '维度管理', hidden: false, permission: ["admin", "examine", "service","enzyme"]},
            },
            {
                path: "disease",
                component: () => import("../views/disease/disease_category"),
                meta: {title: '疾病分类', hidden: false, permission: ["admin"]},
            },
            {
                path: "book_category",
                component: () => import("../views/disease/book_category"),
                meta: {title: '书本疾病分类', hidden: false, permission: ["admin"]},
            },
            {
                path: "book_category_group",
                component: () => import("../views/disease/book_category_group"),
                meta: {title: '书本维度组合', hidden: false, permission: ["admin"]},
            }
        ]
    },
    {
        path: '/login',
        component: () => import("../views/user/login"),
        meta: {title: '订单审核', hidden: true}
    },
    {
        path: '/task_log',
        component: () => import("../views/task/post_log"),
        meta: {title: '查看提交内容', hidden: true}
    }
]
const router = new VueRouter({
    routes
})

export {
    router,
    routes
}
